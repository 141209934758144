import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

import {BusinessSearchComponent} from './components/business-search/business-search.component';
import {CustomerSearchComponent} from './components/customer-search/customer-search.component';
import {BrunnenService} from './services/brunnen.service';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {PersonResolver} from './services/person-resolver.service';
import {MscPushService} from './services/msc-push.service';
import {AppInitService, AppInitServiceFactory} from './services/app-init.service';
import {AngularCommonsModule, LogoutService, PersonsModule, TauresMenueModule} from '@taures/angular-commons';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {OauthLogoutService} from './services/oauth-logout.service';
import {OAuthModule} from 'angular-oauth2-oidc';
import {MaterialModule} from './material.module';
import {NotDeletedPipe} from './pipes/not-deleted.pipe';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {CreateCustomerComponent} from './components/create-customer/create-customer.component';
import {CrmService} from './services/crm.service';
import {EchseService} from './services/echse.service';
import {PersonManagementService} from './services/person-management.service';
import {ConsultantOfDialogComponent} from './components/consultant-of-dialog/consultant-of-dialog.component';
import {DdeService} from "./services/dde.service";
import {ErstinformationService} from "./services/erstinformation.service";
import {
  CreateCustomerRedirectDialogComponent
} from './components/create-customer-redirect-dialog/create-customer-redirect-dialog.component';
import {
  CreateCommercialCustomerDialogComponent
} from './components/create-commercial-customer-dialog/create-commercial-customer-dialog.component';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {PlzService} from "./services/plz-service";
import {UniqueEmployerValidator} from "./components/create-commercial-customer-dialog/unique-employer.validator";
import {KonzeptHeaderComponent} from './components/konzept-header/konzept-header.component';
import {KonzeptPageComponent} from './components/konzept-page/konzept-page.component';
import {KonzeptAreaComponent} from './components/konzept-area/konzept-area.component';
import {KonzeptButtonComponent} from './components/konzept-button/konzept-button.component';
import {KonzeptSubheaderComponent} from './components/konzept-subheader/konzept-subheader.component';
import {KonzeptFooterComponent} from './components/konzept-footer/konzept-footer.component';
import {DatePipe, registerLocaleData} from '@angular/common';
import {SoftfairPushDispatchComponent} from './components/softfair-push-dispatch/softfair-push-dispatch.component';
import {ProblemDetailInterceptor} from "./interceptors/problem-detail-interceptor";
import {MatBadge} from "@angular/material/badge";

import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const APP_DATE_FORMAT = {
  parse: {
    dateInput: 'L',
    monthInput: 'MMMM',
    timeInput: 'LT',
    datetimeInput: 'L LT'
  },
  display: {
    dateInput: 'L',
    monthInput: 'MMMM',
    datetimeInput: 'L LT',
    timeInput: 'LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
    popupHeaderDateLabel: 'ddd, DD MMM'
  },
};

@NgModule({
  declarations: [
    AppComponent,
    BusinessSearchComponent,
    CustomerSearchComponent,
    CreateCustomerComponent,
    PageNotFoundComponent,
    NotDeletedPipe,
    ConsultantOfDialogComponent,
    CreateCustomerRedirectDialogComponent,
    CreateCommercialCustomerDialogComponent,
    KonzeptPageComponent,
    KonzeptAreaComponent,
    KonzeptHeaderComponent,
    KonzeptSubheaderComponent,
    KonzeptFooterComponent,
    KonzeptButtonComponent,
    SoftfairPushDispatchComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    OAuthModule.forRoot({resourceServer: {sendAccessToken: true}}),
    AngularCommonsModule,
    PersonsModule,
    TauresMenueModule,
    MatAutocompleteModule,
    MatBadge,
  ],
  providers: [
    BrunnenService,
    PersonResolver,
    MscPushService,
    AppInitService,
    CrmService,
    ErstinformationService,
    EchseService,
    PersonManagementService,
    DdeService,
    PlzService,
    UniqueEmployerValidator,
    DatePipe,
    {provide: LogoutService, useClass: OauthLogoutService},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMAT},
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitServiceFactory,
      multi: true,
      deps: [AppInitService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProblemDetailInterceptor,
      multi: true
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {disableClose: true, hasBackdrop: true}
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR'
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE'
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      }
    },
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: false}},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMAT},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
